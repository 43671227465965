<template>
  <section class="eventos text-white pt-topmenu xl:pt-0 white-section">
    <div class="hidden xl:block">
      <divider white />

      <!-- Header -->
      <div class="mx-xlpadding xxl:mx-xxlpadding flex justify-end mb-10">
        <span
          class="text-sm circled circled--white"
          style="width: 66px; height: 33px; margin-top: 10px"
          >Eventos</span
        >
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row md:justify-between md:items-end mb-xlpadding mx-padding md:mx-mdpadding lg:mx-lgpadding xl:mx-xlpadding xxl:mx-xxlpadding mt-5 md:mt-10"
    >
      <div class="md:w-half">
        <h1
          class="font-title italic text-bigtitle lg:text-lgtitle xl:text-xltitle leading-bigtitle lg:leading-lgtitle xl:leading-xltitle md:max-w-block"
        >
          Benditos <br />
          Eventos.
        </h1>
      </div>
      <div
        class="md:w-half md:pl-8 mt-5 md:mt-0 text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium"
      >
        No organizamos eventos, creamos momentos especiales que se vuelven
        inolvidables.
      </div>
    </div>

    <!-- Cover image -->
    <img
      src="../assets/pictures/cover-eventos.jpg"
      :srcset="`
        ${require(`../assets/pictures/cover-eventos${
          $mq === 'xs' ? '-mobile' : ''
        }.jpg`)},
        ${require(`../assets/pictures/cover-eventos${
          $mq === 'xs' ? '-mobile' : ''
        }@2x.jpg`)} 2x,
        ${require(`../assets/pictures/cover-eventos${
          $mq === 'xs' ? '-mobile' : ''
        }@3x.jpg`)} 3x
        `"
      style="width: 100%"
    />

    <div
      class="flex flex-col md:flex-row md:justify-between m-padding md:m-mdpadding lg:m-lgpadding xl:m-xlpadding xxl:mx-xxlpadding my-xlpadding"
    >
      <div
        class="text-h2 lg:text-lgh2 leading-h2 lg:leading-lgh2 font-medium md:w-half md:max-w-block"
      >
        Huimos de lo convencional, para dejar espacio a la imaginación. Vamos
        crear algo grande.
      </div>
      <div class="lg:flex lg:flex-wrap md:w-half mt-padding md:mt-0 md:pl-8">
        <div class="flex-1 lg:pr-4">
          <p>
            Abordamos cada proyecto para que sea excepcional, por eso la
            creatividad tiene un papel muy importante en todo lo que hacemos.
          </p>
        </div>
        <div class="flex-1 lg:pl-4 mt-4 lg:mt-0">
          <p>
            Desarrollamos, producimos y ejecutamos cada evento con nuestro
            sello, cuidando el más mínimo detalle. Cuéntanos tu idea, la haremos
            grande juntos.
          </p>
        </div>
      </div>
    </div>

    <projects project-type="evento" dark />

    <!-- Contact -->
    <divider white />
    <contact-section />

    <!-- Footer -->
    <JFooter />
    <divider white />
  </section>
</template>

<script>
import Divider from "../components/Divider";
import ContactSection from "../components/ContactSection";
import JFooter from "../components/Footer";
import Projects from "../components/Projects.vue";

export default {
  name: "Eventos",
  components: {
    Divider,
    ContactSection,
    JFooter,
    Projects,
  },
};
</script>

<style lang="scss" scoped>
.eventos {
  background-color: theme("colors.red") !important;
  color: white;
}
</style>
